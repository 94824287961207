import { defineStore } from "pinia";
import { useRuntimeConfig } from "#app";

export const useUserStore = defineStore("user", {
  state: () => ({
    account: {},
  }),
  actions: {
    async getProfile(payload) {
      const { token, data } = useAuth();
      const fetch = await $fetch(
        `${useRuntimeConfig().public.api}/user/teams`,
        {
          query: {
            username: data.value.username,
          },
          headers: {
            authorization: token.value,
          },
        }
      );
      if (fetch) {
        this.account = fetch;
      }
    },
  },
});
